const axios = require('axios');

export default {
    fetchLanguageRootCategories(isoCode) {
        return axios.get(process.env.VUE_APP_API_BASE_URL + "/cats/roots" + "?lang=" + isoCode);
    },

    fetchSubCategories(categoryId, languageIsoCode) {
        // TODO::change the route when the back-end is implemented
        return axios.get(process.env.VUE_APP_API_BASE_URL + "/cats/all" + "?lang=" + languageIsoCode + "&cat=" + categoryId);
    },

    fetchCategoriesInfo(categoriesIds) {
        let url = "https://run.mocky.io/v3/b4ee7106-0906-4246-8359-70dc82370b90";
        
        return axios.get(
            url + "?id=" + categoriesIds.join(',')
        );
    }
}