import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./i18n";
import PlatformManager from "./PlatformManager.js";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "./assets/custom.css";
//import dbVersions from "@/mobile-data-service/dbVersions";
import Confirm from "./confirm.js";
Vue.prototype.$confirm = Confirm;
// import Langs from '../src/assets/langs.json';
Vue.config.productionTip = false;

//import { EventBus } from "@/event-bus.js";
if (process.env.VUE_APP_PLATFORM == "web") {
  require("./registerServiceWorker.js");
}

let AppObj = {
  vuetify,
  router,
  i18n,
  data: {
    platformManager: PlatformManager.getManager(),
    languages: [],
    langSelected: false,
    firstRun: true,
    locale: "ar",
    subCategories: {},
    showSubCategories: false,
    currActiveCategory: null,
  },
  created() {
    this.rtls = ["ar", "ur", "ps", "ug", "fa", "ku"];
    this.initUI();
  },
  mounted() {},
  watch: {
    "$route.query.lang"(val) {
      if (!val) return;
      this.changeLanguage(val);
    },
    currActiveCategory(val) {
      console.log(val);
    },
  },
  methods: {
    toggleDark() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      //window.localStorage.setItem("dark", this.$vuetify.theme.dark ? "1" : ""); // localStorage store strings, so use empty string as bool false
      window.localStorage.setItem("light", this.$vuetify.theme.dark ? "" : "1"); // localStorage store strings, so use empty string as bool false
      
      if(device.platform.toLowerCase() == 'ios'){
        if(this.$vuetify.theme.dark){
            StatusBar.styleLightContent();
        }
        else{
          StatusBar.styleDefault();
        }
      }
    },

    initUI() {
      const isLight = window.localStorage.getItem("light");
      if (isLight) {
        this.$vuetify.theme.dark = false;
        if(typeof device != 'undefined' && device.platform.toLowerCase() == 'ios'){
          StatusBar.styleDefault();
        }
      }
      else{
        if(typeof device != 'undefined' && device.platform.toLowerCase() == 'ios'){
          StatusBar.styleLightContent();
        }
      }
      
      this.firstRun = !window.localStorage.getItem("runBefore");
      this.initLanguage();
      this.fetchLanguages();
      if (this.firstRun) {
        window.localStorage.setItem("runBefore", "1");
      }
    },
    initLanguage() {
      let langCode = null;
      try {
        langCode = window.localStorage.getItem("lang") || "ar";
        this.$route.query.lang = langCode;
      } catch (e) {
        langCode = window.localStorage.getItem("lang") || "ar";
      }
      this.$vuetify.rtl = this.rtls.indexOf(langCode) != -1;
      this.$i18n.locale = langCode;
      this.locale = langCode;
      window.localStorage.setItem("lang", langCode);
    },
    changeLanguage(langCode) {
      //this.$root.platformManager.changeLocale(langCode);
      window.localStorage.setItem("lang", langCode);
      this.$vuetify.rtl = this.rtls.indexOf(langCode) != -1;
      this.$i18n.locale = langCode;
      this.locale = langCode;
    },
    fetchLanguages() {
      this.$root.platformManager
        .fetchLanguages()
        .then((response) => {
          this.$root.languages = this.$root.platformManager.formatLanguagesResponse(
            response
          );
          //EventBus.$emit('languages-loaded');
        })
        .catch((error) => {
          console.log("error fetching the languages", error);
        });
    },
    goHome() {
      this.$router.push({ name: "home" });
    },
  },

  render: (h) => h(App),
};
function loadcss(filename){
  var fileref=document.createElement("link")
  fileref.setAttribute("rel", "stylesheet")
  fileref.setAttribute("type", "text/css")
  fileref.setAttribute("href", filename)
  document.getElementsByTagName("head")[0].appendChild(fileref)
}
if (process.env.VUE_APP_PLATFORM == "mobile") {
  document.addEventListener("deviceready", onDeviceReady, false);
} else if (process.env.VUE_APP_PLATFORM == "web") {
  let isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
  
  let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") != -1;
  
  if(isAndroid) {
    loadcss("./css/android.css");
  }
  else if(isIOS) {
    loadcss("./css/ios.css");
  }
  else{ // desktop
    loadcss("./css/desktop.css");
  }
  loadcss("./css/web.css");

  new Vue(AppObj).$mount("#app");
}

function onDeviceReady() {
  new Vue(AppObj).$mount("#app");
  
  if(device.platform.toLowerCase() == 'android'){
    loadcss("./css/android.css");
  }
  else{
    loadcss("./css/ios.css");
  }
  loadcss("./css/native-app.css");

  /*
  dbVersions.initTranses();
  setTimeout(function() {
    dbVersions.checkUpdatedVersions().then(() => {
      if (dbVersions.checkLangDBUpdate(i18n.locale)) {
        Confirm.open(i18n.t("confirm.update"), i18n.t("confirm.sync_db"), {
          color: "lime darken-4",
        }).then((confirm) => {
          if (confirm) {
            dbVersions.updateDBLang(i18n.locale).then(function() {
              alert(i18n.t("confirm.sync_db_success"));
            });
          }
        });
      }
    });
  }, 3000);
  */
}
