<template>
    <v-card class="mx-auto px-5 py-5 mb-5" style="width: 50%">
        <v-card-title>{{ $t('side_menu.contact_us') }}</v-card-title>
        <v-simple-table>
            <tbody>
                <tr>
                    <td>{{ $t('side_menu.email') }}</td>
                    <td>info@hadeethenc.com</td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>

<script>
export default {
    
}
</script>