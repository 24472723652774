<template>
    <ul>
        <template v-for='(category, index) in subCategories'>
            <li v-if='category.sub_categories.length > 0' :key="'subcatg' + index + Math.random()" :class="{'my-5': true, 'ident-to-right': $vuetify.rtl, 'ident-to-left': ! $vuetify.rtl}">
                <a class="py-5 px-1 text-subtitle-1" :id="cat_id==category.id?'active-subcat':''" @click="goToSubCategory(category)">- {{ category.title }}</a>
                <SubCategories :subCategories="category.sub_categories"></SubCategories>
            </li>
            <li v-if="category.sub_categories.length == 0" :key="'subcatsingle' + index + Math.random()" :class="{'my-5': true, 'ident-to-right': $vuetify.rtl, 'ident-to-left': ! $vuetify.rtl}">
                <a class="py-5 px-1 text-subtitle-1" :id="cat_id==category.id?'active-subcat':''" @click="goToSubCategory(category)">- {{ category.title }}</a>
            </li>
        </template>
    </ul>
</template>

<script>
    export default {
        name: "SubCategories",
        props: ['subCategories'],

        methods: {
            goToSubCategory(subCategory) {
                //setTimeout(() => {
                    this.$root.currActiveCategory = subCategory;
                    this.$router.replace({
                        name: "hadeeths",
                        query: {
                            lang: this.$route.query.lang,
                            cat_id: subCategory.id,
                        }
                    }).catch((e)=>{console.log(e)});                    
                //}, 400);

            }
                            

        },
        computed: {
            cat_id(){
                return this.$route.query.cat_id || null
            },
        }
              

       
    }
</script>

<style scoped>

    .ident-to-right {
        margin-right: 1em;
    }

    .ident-to-left {
        margin-left: 1em;
    }
    
    a#active-subcat{
        font-weight:bold;
        text-decoration:underline;
    }
    ul{
        list-style:none;
    }
</style>