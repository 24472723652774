<template>
    <div class="my-5" style="padding-bottom:70px">
        <v-bottom-navigation  v-if="hadeeth"  fixed dense height="50" v-model="bottom">
            <!--
            <v-menu top :offset-y="true">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind="attrs"
                    v-on="on"
                    >
                    <span>إعدادات</span>
                    <v-icon >mdi-tune</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item>
                        <v-list-item-title link>A+</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title link>A-</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title link>التشكيل</v-list-item-title>
                    </v-list-item>             
                </v-list>
            </v-menu>
            <v-btn>
                <span>مفضلة</span>
                <v-icon >mdi-heart</v-icon>
            </v-btn>
            -->
            <v-btn @click="share()">
                <span>{{$t('submit')}}</span>
                <v-icon >mdi-share</v-icon>
            </v-btn>
        </v-bottom-navigation>
        <v-scale-transition>
        <div v-if="hadeeth">
            <v-alert
                class="mt-3"
                border="top"
                color="drawerFixed"
                colored-border
            >
            {{hadeeth.title}}
            </v-alert>
            
            <v-card shaped  elevation="3" color="bg lighten-4" class="mx-auto px-4 py-1 mb-5">        
                <v-card-text>            
                    <base-info-component :hadeeth="hadeeth" :arabic_interface="arabic_interface" :languageIsoCode="$route.query.lang" :viewAllTranslations.sync="viewAllTranslations" :noteDialog.sync="showNoteDialog" :notePart.sync="notePart" :noteTrans.sync="noteTrans">
                        <template v-slot:view_more>
                            <div v-if="!arabic_interface" class="view-more-container">
                                <v-btn :outlined="viewMoreFlag" @click="viewMoreArabic" color="lime darken-3" small dark class="mb-2">المزيد...</v-btn>
                                <div v-if="viewMoreFlag && viewMoreData != null">
                                    <div class="my-1 text-right">
                                        <v-chip small class="bg lighten-3">الشرح</v-chip>
                                        <p>
                                            <b class="type--text">{{ viewMoreData.explanation }}</b> 
                                            <v-btn icon small color="orange" @click="notePart='exp';noteTrans='ar';showNoteDialog=true"><v-icon small>mdi-alert</v-icon></v-btn>
                                        </p>
                                    </div>
                                    <v-chip small class="bg lighten-3 my-2">معاني الكلمات</v-chip>
                                    <words-meanings v-if="viewMoreData && typeof viewMoreData.words_meanings != 'undefined' && viewMoreData.words_meanings.length > 0" :wordsMeanings="viewMoreData.words_meanings"></words-meanings>
                                    <v-chip small class="bg lighten-3 my-2">الفوائد</v-chip>
                                    <hints v-if="viewMoreData && typeof viewMoreData.hints != 'undefined' && viewMoreData.hints.length > 0" :hints="viewMoreData.hints" :class="'text-right'"></hints>
                                    <!-- <references v-if="viewMoreData && typeof viewMoreData.reference != 'undefined' && viewMoreData.reference.length > 0" :references="viewMoreData.reference"></references>-->
                                </div>
                            </div>
                        </template>
                    </base-info-component>
        
                    <v-btn :outlined="viewAllTranslations" color="lime darken-3" v-if="hadeeth.transes.length > 0" dark @click="viewAllTranslations = ! viewAllTranslations"><v-icon>mdi-translate</v-icon>{{ $t('show_transes') }}</v-btn>

                </v-card-text>
            </v-card>            

        </div>
        </v-scale-transition>
        
        <v-slide-y-transition>
        <all-translations v-if="hadeeth && viewAllTranslations && appLangs != null" :hadeeth="hadeeth" :appLangs="appLangs" :noteDialog.sync="showNoteDialog" :notePart.sync="notePart" :noteTrans.sync="noteTrans"></all-translations>
        </v-slide-y-transition>
        
        <v-expansion-panels accordion multiple v-model="openPanels">
            <v-expansion-panel v-if="arabic_interface && isFilledArray('words_meanings')">
                <v-expansion-panel-header>{{ $t('meanings') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <words-meanings :wordsMeanings="hadeeth.words_meanings"></words-meanings>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="isFilledArray('hints')">
                <v-expansion-panel-header>{{ $t('fawaed') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <hints :hints="hadeeth.hints"></hints>
                </v-expansion-panel-content>
            </v-expansion-panel>
            
            <references v-if="arabic_interface && hadeeth && hadeeth.reference" :references="hadeeth.reference"></references>
            
            <!-- <hadeeth-categories-info v-if="isFilledArray('categories')" :categoriesIds="hadeeth.categories"></hadeeth-categories-info> -->
        </v-expansion-panels>
        <div v-if="hadeeth">
            <note-dialog :showDialog.sync="showNoteDialog" :id="hadeeth.id" :trans="noteTrans" :part="notePart"></note-dialog>
        </div>
         
    </div>
</template>

<script>

import BaseInfoComponent from '@/components/HadeethComponents/BaseInfoComponent.vue';
import WordsMeanings from '@/components/HadeethComponents/WordsMeanings.vue';
import Hints from '@/components/HadeethComponents/Hints.vue';
import References from '@/components/HadeethComponents/References.vue';
import AllTranslations from '@/components/HadeethComponents/AllTranslations.vue';
import NoteDialog from '@/components/HadeethComponents/NoteDialog.vue';

//import HadeethCategoriesInfo from '@/components/HadeethComponents/HadeethCategoriesInfo.vue';

import { EventBus } from '@/event-bus.js';

export default {
    components: {
        BaseInfoComponent,
        WordsMeanings,
        Hints,
        References,
        AllTranslations,
        NoteDialog
        //HadeethCategoriesInfo,
    },

    data() {
        return {
            hadeeth: null,
            viewMoreData: null,
            viewMoreFlag: false,
            openPanels: [0,1,2],
            viewAllTranslations: false,
            bottom:null,
            showNoteDialog:null,
            notePart:'',
            noteTrans:''
        };
    },

    mounted() {
        //this.formatAppLangs();
        console.log("mounted");
        this.fetchHadeeth();
    },

    methods: {
        share(){
            let txt = this.hadeeth.hadeeth;
            if(this.hadeeth.explanation){
                txt += "\n\n"+'------'+"\n\n"+this.hadeeth.explanation;
            }
            txt += "\n\n"+'https://hadeethenc.com/'+this.$root.locale+'/browse/hadith/'+this.hadeeth.id;
            //console.log(txt);
            this.$root.platformManager.share(txt);
        },
        fetchHadeeth() {
            this.$root.platformManager.fetchSingleHadeeth(this.$route.query.hadeeth, this.$route.query.lang).then( response => {
                this.hadeeth = this.$root.platformManager.formatSingleHadeeth(response);
                if(! this.hadeeth.hadeeth) this.$root.goHome();
            } ).catch( error => {
                console.log("error fetching the hadeeth", error);
            } );
        },

        isFilledArray(index) {
            return this.hadeeth && this.hadeeth[index] && this.hadeeth[index].length > 0;
        },

        viewMoreArabic() {
            if(this.viewMoreFlag === true) {
                this.viewMoreFlag = false;
                return;
            }

            if(this.viewMoreData != null) {
                this.viewMoreFlag = true;
                return;
            }

            this.$root.platformManager.fetchSingleHadeeth(this.$route.query.hadeeth, 'ar').then( response => {
                this.viewMoreData = this.$root.platformManager.formatSingleHadeeth(response);
                this.viewMoreFlag = true;
            } ).catch( error => {
                console.log("error fetching the arabic data", error);
            } );
        },

        resetHadeeth() {
            this.hadeeth = null;
            this.viewMoreData = null;
            this.viewMoreFlag = false;
            this.openPanels = [0];
            this.viewAllTranslations = false;
        }
    },

    computed: {
        appLangs() {
            let currLang = this.$route.query.lang;

            let filteredLangs = this.$root.languages.filter((lang)=>{
                return lang.code != 'ar' && lang.code != currLang; 
            });
            let appL = {};
            filteredLangs.forEach(lang => {
                appL[lang.code] = lang.native;
            });
            return appL;
        },
        arabic_interface() {
            return this.$route.query.lang == 'ar';
        },

        shouldViewAllTranslations() {
            return this.hadeeth && this.viewAllTranslations && this.appLangs.length > 0;
        }
        // currentLocale: {
        //     get() {
        //         return this.$i18n.locale;
        //     }
        // }
    },

    watch: {
        "$root.locale"(val){
            this.resetHadeeth();
            this.fetchHadeeth();
        },
        "$route.query.hadeeth"(val){
            this.resetHadeeth();
            this.fetchHadeeth();
        },     
        openPanels(newval) {
            console.log('the opened panels', newval);
        }
    },  
}
</script>