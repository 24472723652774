const db =  function() {
     function deleteDB(isoCode){
         return new Promise((resolve,reject)=>{
            window.plugins.sqlDB.remove(isoCode+'.sqlite', 0, resolve, reject);
         });
     }
     async function copyDB(isoCode){
        try{
            await deleteDB(isoCode);
        }
        catch(e){
        }
        return new Promise((resolve,reject)=>{
            window.plugins.sqlDB.copy(isoCode +".sqlite", 0, resolve, reject);
        });
     }
     function openDB(isoCode){
        return new Promise((resolve,reject)=>{
            let dbObj = window.sqlitePlugin.openDatabase({name: isoCode +".sqlite", location: 'default'}, ()=>{
                resolve(dbObj)    
            }, reject);
        });
     }
     
     async function initDB(isoCode) {
        return new Promise((resolve, reject) => {
            (async ()=>{
                let currVer = window.localStorage.getItem("db_"+isoCode);
                if(! currVer || currVer < 2){ 
                    try{
                        await copyDB(isoCode);
                        console.log(6);
                        window.localStorage.setItem("db_"+isoCode,"2");
                    }
                    catch(e){
                        reject(e);
                        return;
                    }

                }
                console.log(7);

                let dbObj = null;
                try{
                    dbObj = await openDB(isoCode);
                    console.log(8);
                    resolve(dbObj);    
                }
                catch(e){
                    reject();
                }
                console.log("dbObj:"+dbObj);

            })()
        });
    }

    

     async function fetchAll(iso_code,query,params = [])
    {
        let dbObj = await initDB(iso_code);
        let result = await new Promise((resolve, reject) => {
            dbObj.transaction(function (tx) {
                tx.executeSql(query, params, function (tx, resultSet) {
                        //alert(JSON.stringify(resultSet));
                    let res = [];
                        for (var x = 0; x < resultSet.rows.length; x++) {
                            res.push(resultSet.rows.item(x));
                        }
                        resolve(res);
                    },
                    function (tx, error) {
                        alert('SELECT error: ' + error.message + error.code);
                        reject(error);
                    });
            }, function (error) {
                alert('transaction error: ' + error.message + 'code error' + error.code);
                /*if (error.code == 5) {
                    window.plugins.sqlDB.remove(isoCode + '.sqlite', 0, null);
                    dbObj = null;
                    fetchAll(query, iso_code);
                }*/
            }, function () {
                console.log('transaction ok');
            });
        });
  

        return result;

    }

    async function fetchItem(iso_code,query,params = [])
    {
        let dbObj = await initDB(iso_code);
        let result = await new Promise((resolve, reject) => {
            dbObj.transaction(function (tx) {
                tx.executeSql(query, params, function (tx, resultSet) {
                        //alert(JSON.stringify(resultSet.rows));

                        let res = resultSet.rows.length > 0 ? resultSet.rows.item(0): null;
                        resolve(res);
                    },
                    function (tx, error) {
                        alert('SELECT error: ' + error.message + error.code);
                        reject(error);
                    });
            }, function (error) {
                alert('transaction error: ' + error.message + 'code error' + error.code);
                /*if (error.code == 5) {
                    window.plugins.sqlDB.remove(isoCode + '.sqlite', 0, null);
                    dbObj = null;
                    fetchAll(query, iso_code);
                }*/
            }, function () {
                console.log('transaction ok');
            });
        });

        return result;
    }
    /*
    function copyDB(dbURL,isoCode,dbRet) {

        window.plugins.sqlDB.copyDbFromStorage(isoCode + '.sqlite', 0, dbURL + '/', true,
            function () {
                dbRet();

            },
            function (e) {
                alert("Error Code = " + JSON.stringify(e));
                dbRet();
            }
        );
    }
    */
    function prepareTest(){
        window.plugins = {}
        window.plugins.sqlDB = {}
        window.plugins.sqlDB.copy = function(a,b,c,d){
            setTimeout(()=>{
                d()
            }, 500);
        }
        window.plugins.sqlDB.remove = function(a,b,c,d){
            setTimeout(()=>{
                c()
            }, 500);
        }
        window.sqlitePlugin = {}
        window.sqlitePlugin.openDatabase = function(a,b,c){
            setTimeout(()=>{
                b()
            }, 500);
            return 'aaaa';
        }
    
    }
    return {
        fetchAll:fetchAll,
        fetchItem: fetchItem,
        prepareTest: prepareTest
        //copyDB:copyDB,
}
}();

module.exports = db;