const axios = require('axios');

export default {
    fetchHadeeths(categoryId, isoCode, page, perPage) {
        let baseUrl = process.env.VUE_APP_API_BASE_URL + "/items";
        return axios.get(
            baseUrl + 
            "?cat=" + categoryId + 
            "&lang=" + isoCode + 
            "&page=" + page + 
            "&per_page=" + perPage
        );
    },

    fetchSingleHadeeth(hadeethId, languageIsoCode) {
        let baseUrl = process.env.VUE_APP_API_BASE_URL + "/hadeeth";

        return axios.get(
            baseUrl +
            "?id=" + hadeethId + 
            "&lang=" + languageIsoCode
        );
    },

    fetchSearchResults(searchPhrase, languageIsoCode) {
        let baseUrl = process.env.VUE_APP_API_BASE_URL+"/hadeeth/search/";
        console.log(searchPhrase);
        return axios.get(
            baseUrl +
            "?search_phrase=" + searchPhrase + 
            "&lang=" + languageIsoCode
        );
    }
}