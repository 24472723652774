import Confirm from './components/Confirm.vue'
import Vue from 'vue'

const instance = new (Vue.extend(Confirm))()

document.addEventListener('DOMContentLoaded',function(){
    instance.$vuetify = { breakpoint: {}}
    instance.$mount(document.createElement('div'))
})

export default instance