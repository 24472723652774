module.exports = {
    formatList(response) {
        if(response.length == 0 || response == null) {
            return [];
        }
        let formatted = {
            data: [],
            meta: {
                current_page: 1,
                last_page: 1,
            }
        };

        //let transes = this.getAllHadeethsTranses();

        response.forEach(element => {
            formatted.data.push({
                id: element.id,
                title: element.title,
                transes: element.transes.split(',')//transes[element.id].split(',')
            });
        });

        formatted.meta.current_page = response[0].page;
        formatted.meta.last_page = Math.ceil( response[0].total_hadeeths / response[0].per_page );

        return formatted;
    },

    formatSingleHadeeth(response,iso_code) {

        if(iso_code == 'ar') {

            return this.formatArabicHadeeth(response);
        }

        return this.formatTranslatedHadeeth(response);
    },

    formatArabicHadeeth(hadeeth) {

        hadeeth.transes = hadeeth.transes.split(',');
        hadeeth.hints = hadeeth.hints === null || hadeeth.hints == ""? []: JSON.parse(hadeeth.hints);
        hadeeth.words_meanings = hadeeth.words_meanings === null || hadeeth.words_meanings == ""? "": JSON.parse(hadeeth.words_meanings);
        hadeeth.reference = hadeeth.reference === null || hadeeth.reference == ""? "": hadeeth.reference;

        return hadeeth;
    },

    formatTranslatedHadeeth(hadeeth) {
        //let transes = this.getAllHadeethsTranses();
        hadeeth.transes = hadeeth.transes.split(',');//transes[hadeeth.id].split(',');
        hadeeth.hints = hadeeth.hints === null || hadeeth.hints == ""? []: JSON.parse(hadeeth.hints);
        return hadeeth;
    },

    formatSearchResults(response) {
        if(typeof response == 'undefined') {
            return [];
        }

        return response;
    },

    getAllHadeethsTranses()
    {
        return  JSON.parse(window.localStorage.getItem('transes')).data;
    },
}