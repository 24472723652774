<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title class="headline bg lighten-3">{{ $t('search_results') }}:</v-card-title>
            <v-card-text>
                <div class="text-center my-5" v-if="fetching">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </div>

                <p v-if="results.length == 0 && ! fetching" class="my-5">{{ $t('no_res') }}</p>
                <div class="my-5">
                    <div class="my-5" v-for="(result, index) in results" :key="'search_result' + index + Math.random()">
                        <a @click="showHadeeth(result)">{{ result.title }}</a>
                    </div>
                </div>
                <v-divider></v-divider>
                <v-btn @click="dialog = ! dialog" class="mt-5 mb-2">{{ $t('close') }}</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import { EventBus } from '@/event-bus.js';

export default {
    props: ['searchResultsDialog', 'searchText', 'searchLanguageIsoCode'],
    data() {
        return {
            fetching: false,
            results: []
        }
    },

    methods: {
        fetchResults() {
            this.$root.platformManager.fetchHadeethSearchResults(this.searchText, this.searchLanguageIsoCode).then( response => {
                this.results = this.$root.platformManager.formatHadeethSearchResults(response);
                this.fetching = false;
            } ).catch((error) => {
                console.log('error fetching hadeeth search results', error);
                this.fetching = false;
            });
        },

        showHadeeth(hadeeth) {
            let currentLocale = this.$root.platformManager.getLocale();

            if(currentLocale.iso_code != this.searchLanguageIsoCode) {
                this.changeLanguage();
            }

            this.$router.push({
                name: 'hadeeth',
                query: {
                    hadeeth: hadeeth.id,
                    lang: this.searchLanguageIsoCode,
                }
            });

            this.dialog = false;
            this.$emit('resultPicked');
        },

        changeLanguage() {
            let language = this.$root.languages.filter((lang) => {
                return lang.code == this.searchLanguageIsoCode;
            })[0];
            
            this.$root.platformManager.changeLocale(language.code, language.native, language.direction);
            let currentLocale = this.$root.platformManager.getLocale();
            this.$vuetify.rtl = currentLocale.direction == 'rtl';
            this.$i18n.locale = currentLocale.iso_code;
            EventBus.$emit('locale-changed');
        }
    },

    computed: {
        dialog: {
            get() {
                return this.searchResultsDialog;
            },

            set(newval) {
                this.$emit('update:searchResultsDialog', newval);
            }
        }
    },

    watch: {
        dialog(val) {
            if(val === true) {
                this.results = [];
                this.fetching = true;
                this.fetchResults();
            }
        }
    },

    destroyed() {
        console.log('here');
        this.results = [];
        this.fetching = false;
    }
}
</script>