<template>
    
    <div class="my-3">
        <p dir="rtl"><b class="type--text text-subtitle-1">{{ arabicText }}</b> <v-btn icon small color="orange" @click="_notePart='hadith';_noteTrans='ar';_noteDialog=true"><v-icon small>mdi-alert</v-icon></v-btn></p>
        <slot name="view_more"></slot>

        <div v-if="!arabic_interface" class="my-5">
            <b class="type--text text-subtitle-1">{{ hadeeth.hadeeth }}</b> <v-btn icon small color="orange" @click="_notePart='hadith';_noteTrans=langIsoCode;_noteDialog=true"><v-icon small>mdi-alert</v-icon></v-btn>
        </div>

        <div>
            <v-chip class="mb-2" color="bg lighten-3" small>{{ $t('exp') }}</v-chip>
            <div><b class="type--text text-subtitle-1">{{ hadeeth.explanation }}</b> <v-btn icon small color="orange" @click="_notePart='exp';_noteTrans='ar';_noteDialog=true"><v-icon small>mdi-alert</v-icon></v-btn></div>
        </div>

    </div>
            
</template>

<script>
export default {
    props: ['hadeeth', 'languageIsoCode', 'arabic_interface', 'viewAllTranslations','noteDialog','notePart','noteTrans'],

    computed: {
        arabicText() {
            return this.hadeeth == null? "": ( this.languageIsoCode == 'ar'? this.hadeeth.hadeeth: this.hadeeth.hadeeth_ar );
        },

        viewAllTranses: {
            get() {
                return this.viewAllTranslations;
            },

            set(newval) {
                this.$emit('update:viewAllTranslations', newval);
            }
        },
        _noteDialog: {
            get() {
                return this.noteDialog;
            },

            set(newval) {
                this.$emit('update:noteDialog', newval);
            }
        },
        _notePart:{
            set(val){
                this.$emit('update:notePart', val);
            },
            get(){
                return this.notePart;
            }
        },
        _noteTrans:{
            set(val){
                this.$emit('update:noteTrans', val);
            },
            get(){
                return this.noteTrans;
            }
        },                

    }
}
</script>