const db = require('../../db.js');
const CategoriesManager = require('./CategoriesManager.js');
const HadeethsFormatter = require('../../formatters/HadeethsFormatter.js');

module.exports = {
    async fetchHadeeths(iso_code, categoryId, page, perPage) {
        let categoriesIds = await CategoriesManager.getAllSubCategoriesIds(iso_code, categoryId);
        let limit = perPage;
        let offset = page == 1? 0: (page - 1) * limit;
        let categoriesJoined = categoriesIds.map( (categoryId) => {
            return "'" + categoryId + "'";
        } ).join(',');

        let query = `
            select distinct hadeeths.id, title, transes, counter.total_hadeeths, ${page} as page, ${perPage} as per_page
            from hadeeths, (
                select count(distinct category_hadeeth.hadeeth_id) as total_hadeeths
                from category_hadeeth
                where category_hadeeth.category_id in (${categoriesJoined})
            ) as counter
            inner join category_hadeeth on (
                hadeeths.id = category_hadeeth.hadeeth_id
                and category_hadeeth.category_id in (${categoriesJoined})
            )
            order by hadeeths.tarteeb desc, hadeeths.id asc
            limit ${limit}
            offset ${offset}
        `;

        let hadeeths = await db.fetchAll(iso_code, query, []);
        
        
        return HadeethsFormatter.formatList(hadeeths);
    },

    async fetchSingleHadeeth(iso_code, hadeethId) {
        let query = `
            select * from hadeeths 
            where id = ?
        `;

        let hadeeth = await db.fetchItem(iso_code, query, [hadeethId]);
        if(iso_code != 'ar') {
            let hadeeth_ar = await db.fetchItem('ar', query, [hadeethId]);
            hadeeth.hadeeth_ar = hadeeth_ar.hadeeth;
        }
        return HadeethsFormatter.formatSingleHadeeth(hadeeth,iso_code);
    },

    async fetchSearchResults(iso_code, searchText) {
        let query = '';
        if(iso_code == 'ar'){
            query = `
            select id, title from hadeeths
            where title_clean like "%${searchText}%" 
            or hadeeth_clean like "%${searchText}%" 
            or explanation_clean like "%${searchText}%" 
        `;
        }
        else{
            query = `
            select id, title from hadeeths
            where title like "%${searchText}%" 
            or hadeeth like "%${searchText}%" 
            or explanation like "%${searchText}%" 
        `;
        }

        return await db.fetchAll(iso_code, query);
    },
}