
// @TODO use contional require instead of static import to avoid including useless platform code in another platform.
//import Web from './data-service/platforms/Web.js';
//import Mobile from './mobile-data-service/platforms/Mobile.js';

export default {
    getManager() {
        let platform = process.env.VUE_APP_PLATFORM;
        if(platform == 'web') {
            return require('./data-service/platforms/Web.js').default;
        }
        else if(platform == 'mobile')
        {
            return require('./mobile-data-service/platforms/Mobile.js').default;
        }
    }
};