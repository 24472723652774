import BaseLanguagesFormatter from '../BaseLanguagesFormatter.js';

export default {
    formatList(response) {
        if(typeof response == 'undefined') {
            return [];
        }
        let languages = response.data;
        BaseLanguagesFormatter.appendDirection(languages);
        return languages;
    }
}