const { arrayToTree } = require('performant-array-to-tree');
const db = require('../../db.js');
const MobileCategoriesFormatter = require('../../formatters/CategoriesFormatter.js');

module.exports = {
    async fetchLanguageRootCategories(iso_code) {
        let query = `
            select id, title, num from categories
            where parent_id = 0
        `;
        let result = await db.fetchAll(iso_code, query);
        return result;
    },

    async fetchAllCategories(iso_code) {
        let query = `
            select id, title, parent_id from categories
        `;
        let result = await db.fetchAll(iso_code, query);
        return result;
    },

    async getAllSubCategoriesIds(iso_code, parentId) {
        let tree = await this.buildCategoriesTree(iso_code);
        let cat = this.findCategoryInTree(tree, parentId);

        if(! cat) {
            throw("Couldn't build the tree");
        }

        // just a workaround to solve the pass by reference javascript problem
        let obj = {
            ids: []
        };
        this._getAllSubCategoriesIds(cat, obj);
        return obj.ids;
    },

    async buildCategoriesTree(iso_code) {
        let categories = await this.fetchAllCategories(iso_code);
        let mapped = categories.map( cat => {
            return {
                id: cat.id,
                parentId: cat.parent_id == '0'? null: cat.parent_id,
            }
        } );

        let tree = await arrayToTree(mapped, {
            dataField: null,
        });
        return tree;
    },

    findCategoryInTree(tree, parentId) {
        let _el = null;
        for(let i = 0; i < tree.length; i++) {
            let element = tree[i];
            if(element.id == parentId) {
                return element;
            }
            if(element.children.length){
                // eslint-disable-next-line no-cond-assign
                if(_el = this.findCategoryInTree(element.children, parentId)){
                    return _el;
                }
            }
        }


        return null;
    },

    _getAllSubCategoriesIds(node, obj) {
        obj.ids.push(node.id);
        if(node.children.length == 0) {
            return;
        }

        for(let i = 0; i < node.children.length; i++) {
            this._getAllSubCategoriesIds(node.children[i], obj);
        }
    },

    //What is the use of this method??
    async fetchSubCategories(iso_code, categoryId) {

        let ids = await this.getAllSubCategoriesIds(iso_code, categoryId);
        let query = `
            select id, title, num, parent_id from categories
            where id in (${ids})
            and id <> '${categoryId}'
            order by cast(id as integer) asc
        `;

        let result = await db.fetchAll(iso_code, query);
        let tree = await MobileCategoriesFormatter.formatSubCategories(result);

        return  tree;
    },

    async fetchAllSubCategories(iso_code) {

        let query = `
            select id, title, parent_id from categories
        `;

        let result = await db.fetchAll(iso_code, query);
        let tree = await MobileCategoriesFormatter.formatSubCategories(result);
        return tree;
    },

}