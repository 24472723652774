<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="py-2 drawerFixed">
          <v-icon color="light">mdi-earth</v-icon>
          <v-spacer></v-spacer>
          <v-btn icon small @click="dialog = false"><v-icon color="light">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-list>
          <v-list-item v-for="(language, i) in $root.languages" :key="'lang' + i" @click="dialog = false;changeLanguage(language.code)">
            <v-list-item-content>
              <v-list-item-title class="mx-3"><b class="type--text">{{language.native}}</b></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon small color="lime darken-2">{{$vuetify.rtl?'mdi-arrow-left':'mdi-arrow-right'}}</v-icon>
            </v-list-item-action>            
            <v-btn v-if="false&&checkUpdates(language.code)" icon @click="dialog = false;updateLanguage(language.code)"><v-icon color="lime darken-2">mdi-update</v-icon></v-btn>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { EventBus } from '@/event-bus.js';
import Confirm from '../../confirm.js'
import i18n from "@/i18n";
//import dbVersions from "@/mobile-data-service/dbVersions";

export default {
  props: ['languagesDialog'],

  data() {
    return{

    }
  },

  created() {
    
  },
  mounted(){
    //console.log("langs mounted");
  },
  methods: {

    changeLanguage(lang) {
      if (lang == this.$route.query.lang) return;
      let query = {...this.$route.query, ...{"lang": lang}};
      delete query['d'];
      setTimeout(() => {
        this.$router.push({
          query: query
        });          
      }, 300);      
    }
    ,
    updateLanguage(lang) {
      Confirm.open(i18n.t('confirm.update'), i18n.t('confirm.sync_db'), { color: 'lime darken-4' })
          .then((confirm) => {
            if (confirm) {
              dbVersions.updateDBLang(lang).then( function () {
                alert(i18n.t('confirm.sync_db_success'));
              });
            }
          });
    }
    ,
    checkUpdates(lang)
    {
      if(this.$root.firstRun)
        return false;
      return dbVersions.checkLangDBUpdate(lang);
    }
    
  },

  computed: {
    dialog: {
      get() {
        return this.languagesDialog;
      },

      set(newval) {
        this.$emit('update:languagesDialog', newval);
      }
    }
    
  },
  watch:{
    dialog(val){
      if(val){
        let query = {...this.$route.query, ...{"d":"1"}};
        this.$router.push({
          query: query
        }).catch(()=>{});
      }
      else{
          if(! this.$route.query.d) return;
          history.go(-1);
      }
    },
    "$route.query.d"(val){
      if(! val) this.dialog = false;
    }
  }
}
</script>