<template>
  <v-row class="pb-5 mb-5">
    <v-col>
    
    
     <v-alert
      class="mt-3 pt-5"
      border="top"
      color="drawerFixed"
      colored-border
    >
      <b class="type--text">{{$t("hadith_list")}}</b>
      <v-btn  icon color="bg" class="my-0" @click="$root.showSubCategories = ! $root.showSubCategories">
        <v-icon color="drawerFixed">mdi-filter</v-icon>
      </v-btn>
    </v-alert>

    <hadeeth-excerpt v-show="hadeeths.length > 0" v-for="(hadeeth, i) in hadeeths" :key="'had_exerpt' + i" :hadeeth="hadeeth"></hadeeth-excerpt>

     <div class="text-center" v-if="fetching">
        <v-progress-circular 
              class="my-4"
              color="lime darken-4"
              indeterminate
              rounded
              size="60"
        ></v-progress-circular>
    </div>

    </v-col>
  </v-row>
</template>

<script>
import HadeethExcerpt from '../components/HadeethExcerpt.vue';
import { EventBus } from '@/event-bus.js';

export default {
  name:"listView",
  components: {
    HadeethExcerpt,
  },
  data() {
    return {
      hadeeths: [],
      fetching: true,
      page: 1,
      perPage: 50,
      lastPage: 1,
      showSubCategories: false,
      subCategories: [],
      pauseScroll:true
      //interval:false
    }
  },
  activated(){
    console.log("Activated!!:"+this.page);
    console.log(this.$route.name+'_'+(this.$route.query.cat_id || 1)+'_'+(this.$route.query.lang || ''));
    this.active = true;
    this.registerScroll();
  },
   
  deactivated(){
    this.active = false;
    clearInterval(this.interval);
  },
  mounted() {
    console.log("list mounted")
    console.log(this.$route.name+'_'+(this.$route.query.cat_id || 1)+'_'+(this.$route.query.lang || ''));
    setTimeout(() => {
      this.fetchHadeeths();
    }, 500);
    setTimeout(() => {
      this.fetchSubCategories();  
    }, 600);
    
  },


  beforeDestroy(){
    window.onscroll = null;
  },

  methods: {
    registerScroll() {
      if(this.interval){
        clearInterval(this.interval);
      }
      this.interval = setInterval(()=>{
        if(this.pauseScroll) return;
        if(this.page >= this.lastPage){
          clearInterval(this.interval);
          return;
        }

        if(window.scrollY > (document.body.scrollHeight-window.innerHeight-50)){
          this.page++;
          this.fetching = true;
          this.pauseScroll = true;
          this.fetchHadeeths();      
        }
      },400); 
    },

    resetHadeeths() {
      this.hadeeths = [];
      this.fetching = true
      this.page = 1;
      //this.perPage = 50;
      this.lastPage = 1;
      this.pauseScroll = true;
      
    },

    fetchHadeeths() {
      this.$root.platformManager.fetchHadeeths(this.$route.query.cat_id, this.$route.query.lang, this.page, this.perPage).then( response => {
        //alert("f2");
        this.populateHadeethsAfterFetch(response);
        this.fetching = false;
        setTimeout(() => {
          this.pauseScroll = false;
        }, 300);
      } ).catch((error) => {
        console.log('error fetching hadeeths', error);
        this.fetching = false;
        
      });
    },

    fetchSubCategories() {
      
      if(this.$root.subCategories[this.$root.locale] && this.$root.subCategories[this.$root.locale].length) return;
      
      this.$root.platformManager.fetchSubCategories(this.$route.query.cat_id, this.$route.query.lang).then( response => {
        this.$set(this.$root.subCategories, this.$root.locale, this.$root.platformManager.formatSubCategories(response));
        //this.$root.subCategories[this.$root.locale] = this.$root.platformManager.formatSubCategories(response);
      } ).catch((error) => {
        console.log('error fetching sub-categories', error);
      });
    },

    populateHadeethsAfterFetch(response) {
      let formatted = this.$root.platformManager.formatHadeethsResponse(response);
      //console.log("FORMATED NUM:"+formatted.length);
      if(formatted == null) {
        return;
      }
      //console.log("GOING WITH FORMATED NUM:"+formatted.length);
      formatted.hadeeths.forEach( (item) => {
        this.hadeeths.push(item);
      } );

      this.page = formatted.meta.current_page;
      this.lastPage = formatted.meta.last_page;
      //alert(this.lastPage);
    },

    changeSubCategory() {
      console.log('changing sub-category');
      this.resetHadeeths();
      this.fetchHadeeths();
    },
    languageEventListener(){

      if(typeof this.$route.query.cat_id == 'undefined') {
        return;
      }


      this.resetHadeeths();
      this.fetchHadeeths();
      this.fetchSubCategories();
    },
  },

  computed: {
    cat_id(){
      return this.$route.query.cat_id || null
    },
    
  },
  watch:{
   
    cat_id(val){
      if(! this.active) return;
      this.showSubCategories = false;
      //this.changeSubCategory();
    },
    "$root.locale"(val){
        console.log("watch locale: before");
        if(! this.active) return;
        console.log("watch locale: after");
        this.languageEventListener();
    }    
  }
}
</script>