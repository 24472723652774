const { arrayToTree } = require('performant-array-to-tree');

module.exports = {
    formatList(response) {
        if(typeof response == 'undefined') {
            return [];
        }

        return response;
    }
    ,
    formatSubCategories(response) {
        if(typeof response == 'undefined') {
            return [];
        }

        let categories =  response.map((category) => {
            return {
                id: category.id,
                parentId: category.parent_id == "0"? null: category.parent_id,
                title: category.title,
            }
        });

        let arr = arrayToTree(categories, {
            childrenField: 'sub_categories',
            dataField: null,
        });

        return  arr;
    },
}