<template>
    <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('refs') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
            <p>{{ references }}</p>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    props: ['references']
}
</script>