export default {
    formatList(response) {
        if(typeof response == 'undefined') {
            return null;
        }

        return {
            hadeeths: response.data.data,
            meta: {
                current_page: response.data.meta.current_page,
                last_page: response.data.meta.last_page,
            }
        };
    },

    formatSingleHadeeth(response) {

        if(typeof response == 'undefined') {
            return null;
        }
        
        return response.data;
    },

    formatSearchResults(response) {
        if(typeof response == 'undefined') {
            return [];
        }

        return response.data;
    },
}