import BrowserLocaleManager from '../drivers/BrowserLocalStorage/LocaleManger.js';

import WebLanguagesManager from '../drivers/WebApi/LanguagesManager.js';
import WebLanguagesFormatter from '../core/formatters/WebApi/LanguagesFormatter.js';

import WebCategoriesManager from '../drivers/WebApi/CategoriesManager.js';
import WebCategoriesFormatter from '../core/formatters/WebApi/CategoriesFormatter.js';

import WebHadeethsManager from '../drivers/WebApi/HadeethsManager.js';
import WebHadeethsFormatter from '../core/formatters/WebApi/HadeethsFormatter.js';

export default {
    share(text){
        //console.log(txt);
        navigator.share({"text":text});
    },
    getLocale() {
        window.localStorage.getItem('lang') || 'ar';
    },

    changeLocale(isoCode) {
        window.localStorage.setItem('lang',isoCode);
    },

    fetchLanguages() {
        return WebLanguagesManager.fetchAll();
    },

    formatLanguagesResponse(response) {
        return WebLanguagesFormatter.formatList(response);
    },

    fetchLanguageRootCategories(isoCode) {
        return WebCategoriesManager.fetchLanguageRootCategories(isoCode);
    },

    formatLanguageRootCategoriesResponse(response) {
        return WebCategoriesFormatter.formatList(response);
    },

    fetchHadeeths(categoryId, isoCode, page, perPage) {
        return WebHadeethsManager.fetchHadeeths(categoryId, isoCode, page, perPage);
    },

    formatHadeethsResponse(response) {
        return WebHadeethsFormatter.formatList(response);
    },

    fetchSingleHadeeth(hadeethId, languageIsoCode) {
        return WebHadeethsManager.fetchSingleHadeeth(hadeethId, languageIsoCode);
    },

    formatSingleHadeeth(response) {
        return WebHadeethsFormatter.formatSingleHadeeth(response);
    },

    fetchSubCategories(categoryId, languageIsoCode) {
        return WebCategoriesManager.fetchSubCategories(categoryId, languageIsoCode);
    },

    formatSubCategories(response) {
        return WebCategoriesFormatter.formatSubCategories(response);
    },

    fetchHadeethSearchResults(searchPhrase, languageIsoCode) {
        return WebHadeethsManager.fetchSearchResults(searchPhrase, languageIsoCode);
    },

    formatHadeethSearchResults(response) {
        return WebHadeethsFormatter.formatSearchResults(response);
    },

    fetchCategoriesInfo(categoriesIds, languageIsoCode) {
        return WebCategoriesManager.fetchCategoriesInfo(categoriesIds, languageIsoCode);
    },

    formatCategoriesInfo(response) {
        return WebCategoriesFormatter.formatCategoriesInfo(response);
    },
};