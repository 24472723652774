import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  treeShake: true,
  customVariables: ["@/assets/scss/vuetify_variables.scss"],
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark:true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        light: "#F9F9F9",
        primary: colors.lime,
        anchor: colors.lime,
        bg: colors.grey,
        drawer:"#6B7B49",
        drawerFixed:"#6B7B49",
        toolbar:colors.grey.lighten4,
        type: "#333",
      },
      dark: {
        light: "#F9F9F9",
        primary: colors.lime,
        anchor: colors.lime,
        bg: colors.black,
        drawer:"#232818",
        drawerFixed:"#6B7B49",

        toolbar:colors.grey.darken4,

        type: "#eee",
      },
    },
  },
});
