<template>
  <v-app id="inspire">
    <v-navigation-drawer
      temporary
      dark
      v-model="drawer"
      app
      :right="$vuetify.rtl"
      color="drawer"
    >
      <SideMenu></SideMenu>
    </v-navigation-drawer>

    <v-app-bar app color="toolbar">
      <v-app-bar-nav-icon  color="drawerFixed" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-btn color="type" v-if="showBackIcon" icon @click="goBack()">
        <v-icon>{{ backIcon }}</v-icon>
      </v-btn>
      <v-toolbar-title
        ><img
          @click="$root.goHome()"
          src="./assets/app_logo.png"
          style="vertical-align:text-top;height:54px"
      /></v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn color="type" icon @click="languagesDialog = !languagesDialog">
        <v-icon>mdi-earth</v-icon>
      </v-btn>
      <v-btn color="type" icon @click="$root.toggleDark()">
        <v-icon>mdi-brightness-6</v-icon>
      </v-btn>

      <v-btn
        color="type"
        icon
        @click="hadeethSearchFilterDialog = !hadeethSearchFilterDialog"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <keep-alive include="categoriesView,listView">
          <router-view
            :key="
              $route.name +
                '_' +
                ($route.query.cat_id || 1) +
                '_' +
                ($route.query.lang || '')
            "
          ></router-view>
        </keep-alive>
      </v-container>
    </v-main>

    <Languages :languagesDialog.sync="languagesDialog"></Languages>
    <HadeethSearchFilter
      :hadeethSearchFilterDialog.sync="hadeethSearchFilterDialog"
    ></HadeethSearchFilter>
    <SubCategoriesDialog
      :showSubCategories.sync="$root.showSubCategories"
    ></SubCategoriesDialog>
  </v-app>
</template>

<script>
import Languages from "./components/navBar/Languages.vue";
import SideMenu from "./components/SideMenu.vue";
import HadeethSearchFilter from "./components/HadeethSearchFilter.vue";
import SubCategoriesDialog from "./components/SubCategoriesDialog.vue";
import { EventBus } from "@/event-bus.js";
//import db from "./mobile-data-service/db";
export default {
  components: {
    Languages,
    SideMenu,
    HadeethSearchFilter,
    SubCategoriesDialog,
  },
  data() {
    return {
      drawer: false,
      languagesDialog: false,
      hadeethSearchFilterDialog: false,
    };
  },
  methods: {
    goBack() {
      history.go(-1);
    },
  },

  computed: {
    backIcon() {
      return this.$vuetify.rtl ? "mdi-arrow-right" : "mdi-arrow-left";
    },
    showBackIcon() {
      return this.$route.name != "categories";
    },
  },
  created() {},
  mounted() {
    //db.prepareTest();
    //db.fetchItem('en','');
    if (
      window.cordova &&
      window.cordova.platformId.toLowerCase() == "android"
    ) {
      document.addEventListener("backbutton", () => {
        if (this.$route.name == "categories" && !this.$route.query.d) {
          navigator.app.exitApp();
          return;
        }
        history.go(-1);
      });
    }
  },
  watch: {
    "$root.languages"() {
      if (this.$root.firstRun) {
        setTimeout(() => {
          this.languagesDialog = true;
        }, 200);
      }
    },
    drawer(val) {
      if (val) {
        let query = { ...this.$route.query, ...{ d: "1" } };
        this.$router
          .push({
            query: query,
          })
          .catch(() => {});
      } else {
        if (!this.$route.query.d) return;
        history.go(-1);
      }
    },
    "$route.query.d"(val) {
      if (!val) this.drawer = false;
    },
  },
};
</script>
