<template>
    <v-dialog fullscreen v-model="dialog">
        <v-card>
            <v-card-title class="bg lighten-3">
                {{ $t('search_in') }}:
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>

            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col md="9" class="mx-auto">
                            <v-select :items="$root.languages" required item-text="native" item-value="code" v-model="searchLanguageIsoCode"></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col md="9" class="mx-auto">
                            <v-text-field :placeholder="$t('enter_search_phrase')" required v-model="searchText"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="9" class="mx-auto mb-2">
                            <v-btn block cols="3" @click="fetchResults()">{{ $t('search') }}</v-btn>
                        </v-col>                        
                    </v-row>
                </v-form>
                <div class="text-center my-5" v-if="fetching">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </div>
                <v-row class="my-5" v-if="results">
                    <v-col md="9" class="mx-auto">
                        <v-alert dense text color="success" class="my-4" v-for="(result, index) in results" :key="'search_result' + index">
                            <a @click="showHadeeth(result)"><b class="type--text">{{ result.title }}</b></a>
                        </v-alert>
                    </v-col>
                </v-row>
                <div class="text-center mt-4" v-if="noRes"><b>{{$t('no_res')}}</b></div>   
            </v-card-text>
           
        </v-card>
        
    </v-dialog>
</template>

<script>

import { EventBus } from '@/event-bus.js';
import HadeethSearchResults from './HadeethSearchResults.vue';

export default {
    props: ['hadeethSearchFilterDialog'],

    components: {
        //HadeethSearchResults,
    },
    
    data() {
        return {
            searchResultsDialog: false,
            searchText: "",
            results:null,
            fetching:null,
            searchLanguageIsoCode:'ar',
            noRes: false
        }
    },
    mounted(){
        this.searchLanguageIsoCode = this.$root.locale;
    },
    methods: {
        fetchResults() {
            this.noRes = false;
            let txt = this.searchText;
            if(txt.length < 3 || txt.length > 120){
                alert(this.$t("validate_term"));
                return;
            }
            let lang = this.searchLanguageIsoCode;
            this.fetching = true;
            if(lang == 'ar'){
                txt = txt.replace(/[أإآ]/g, "ا");
                txt = txt.replace(/[ى]/g, "ي");
            }
            this.$root.platformManager.fetchHadeethSearchResults(txt, lang).then( response => {
                this.results = this.$root.platformManager.formatHadeethSearchResults(response);
                this.fetching = false;
                if(!this.results.length){
                    this.noRes = true;
                }
            } ).catch((error) => {
                console.log('error fetching hadeeth search results', error);
                this.fetching = false;
                this.noRes = true;
            });
        },
        showHadeeth(hadeeth) {
            this.$router.replace({
                name: 'hadeeth',
                query: {
                    hadeeth: hadeeth.id,
                    lang: this.searchLanguageIsoCode,
                }
            });
        },        
    },
    
    computed: {


        dialog: {
            get() {
                return this.hadeethSearchFilterDialog;
            },

            set(newval) {
                this.$emit('update:hadeethSearchFilterDialog', newval);
            }
        },
    },
    watch:{
        "$root.locale"(val){
            this.searchLanguageIsoCode = val;
        },
        
        dialog(val){
            if(val){
                let query = {...this.$route.query, ...{"d":"1"}};
                this.$router.push({
                query: query
                }).catch(()=>{});
            }
            else{
              if(! this.$route.query.d) return;
              history.go(-1);
            }
        },
        "$route.query.d"(val){
            if(! val) this.dialog = false;
        }        
    }
}
</script>