<template>
    <div style="width: 100%" class="my-3">
        <v-expansion-panels  accordion multiple>
        <v-expansion-panel  class="bg lighten-4" v-for="trans in filteredTranses" :key="trans">
            <v-expansion-panel-header @click="loadContentIfNotLoaded(trans)">{{ typeof appLangs[trans] != 'undefined'? appLangs[trans]: "unknown language to the server" }}</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-progress-linear v-show="!translations[trans]" color="primary" indeterminate></v-progress-linear>
                <div v-show="translations[trans] && translations[trans].hadeeth" :class="{'my-5': true, 'text-left': ! isRtl(trans), 'text-right': isRtl(trans)}">
                    <p v-if="translations[trans]">{{ translations[trans].hadeeth }}  <v-btn icon small color="orange" @click="_notePart='hadith';_noteTrans=trans;_noteDialog=true"><v-icon small>mdi-alert</v-icon></v-btn></p>
                </div>

                <div v-show="translations[trans] && translations[trans].explanation" :class="{'my-5': true, 'text-left': ! isRtl(trans), 'text-right': isRtl(trans)}">
                    <v-chip color="bg lighten-3" small>{{ $t('exp') }}</v-chip>
                    <p v-if="translations[trans]">{{translations[trans].explanation}}  <v-btn icon small color="orange" @click="_notePart='exp';_noteTrans=trans;_noteDialog=true"><v-icon small>mdi-alert</v-icon></v-btn></p>
                </div>
            
                <hints v-if="translations[trans] && translations[trans].hints && translations[trans].hints.length" :hints="translations[trans].hints" :forceArabic="false" :class="{'my-5': true, 'text-left': ! isRtl(trans), 'text-right': isRtl(trans)}"></hints>

            </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>

import Hints from '@/components/HadeethComponents/Hints.vue';

export default {
    props: ['hadeeth', 'appLangs'],

    components: {
        Hints,
    },

    data() {
        return {
            translations: [],
            panel:[0,1,2]
        }
    },

    created() {
        // initially load the first translation to solve the bug in vuetify 
        //this.initTranslations();
    },

    methods: {
        /* @TODO remove after check
        initTranslations() {
            if(this.filteredTranses.length == 0) {
                return;
            }

            let isoCode = this.filteredTranses[0];
            this.loadContentIfNotLoaded(isoCode);
        },
        */
        loadContentIfNotLoaded(trans) {
            this.panel.push(trans);
            if(this.isLoaded(trans)) {
                return;
            }
            setTimeout(() => {
                this.fetchTrans(trans);    
            }, 700);
            
        },

        isLoaded(isoCode) {
            for(let langIsoCode in this.translations) {
                if(langIsoCode == isoCode && this.translations[langIsoCode].hadeeth.length > 0) {
                    return true;
                }
            }

            return false;
        },

        fetchTrans(trans) {
            this.$root.platformManager.fetchSingleHadeeth(this.hadeeth.id, trans).then( response => {
                let hadeeth = this.$root.platformManager.formatSingleHadeeth(response);
                this.$set(this.translations, trans, hadeeth);
            } ).catch( error => {
                console.log("error fetching a translation", error);
            } );   
        },

        isRtl(isoCode) {
            return this.$root.rtls.includes(isoCode);
        }
    },

    computed: {
        filteredTranses() {
            return this.hadeeth.transes.filter( trans => {
                for(let isoCode in this.appLangs) {
                    if(isoCode == trans) {
                        return true;
                    }
                }

                return false;
            } );
        },
        _noteDialog: {
            get() {
                return this.noteDialog;
            },

            set(newval) {
                this.$emit('update:noteDialog', newval);
            }
        },
        _notePart:{
            set(val){
                this.$emit('update:notePart', val);
            },
            get(){
                return this.notePart;
            }
        },
        _noteTrans:{
            set(val){
                this.$emit('update:noteTrans', val);
            },
            get(){
                return this.noteTrans;
            }
        },          
    }
}
</script>
<style type="text/css">
.text-left{
    direction:ltr;
}
.text-right{
    direction:rtl
}
</style>