export default {
    appendDirection(languages) {
        let rtl = ['ar', 'arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi'];

        for(let i = 0; i < languages.length; i++) {
            languages[i].direction = rtl.includes(languages[i].code) ? 'rtl': 'ltr';
        }

        return languages;
    }
}