<template>
  <v-row row justify-center>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <small>{{$t('send_note')}}</small>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>          
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-row>
              <v-col>
                <v-text-field :label="$t('name')" v-model="name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field :label="$t('email')" v-model="email"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea :error="validationError" :label="$t('note')" v-model="note"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea :label="$t('suggested_trans') + ' - ' + $t('optional')" v-model="suggested_trans"></v-textarea>
              </v-col>
            </v-row>            
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="lime darken-2" dark @click="saveNote()">{{$t('submit')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      bottom
      center
      color="lime darken-4"
      :timeout="3000"
    >
      {{$t('done')}}
      <v-btn
        icon
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>    
  </v-row>
</template>

<script>
  
  import axios from 'axios';

	export default {
    props: ['rangeId','showDialog','id','part','trans'],
    data() {
      return {
        name: "",
        email: "",
        note: "",
        suggested_trans:"",
        validationError: false,
        snackbar:false,
      }
    },
    mounted(){
      this.name  = window.localStorage.getItem('name') || '';
      this.email = window.localStorage.getItem('email') || '';
    },
    computed:{
			dialog:{
				set(val){
					this.$emit('update:showDialog', val);
				},
				get(){
					return this.showDialog;
				}
			}      

    },
    watch:{
      dialog(val){
        if(val){
          let query = {...this.$route.query, ...{"d":"1"}};
          this.$router.push({
            query: query
          }).catch(()=>{});
        }
        else{
          if(! this.$route.query.d) return;
          history.go(-1);
        }
      },
      "$route.query.d"(val){
        if(! val) this.dialog = false;
      }
    },
    methods: {
      saveNote()
      {
        this.validationError = false;
        if(this.note.length < 3) {
          this.validationError = true;
          return;
        }
        
        window.localStorage.setItem('name', this.name);
        window.localStorage.setItem('email', this.email);
        const sendNoteURL = "https://hadeethenc.com/ar/ajax/note/add";
        
        axios.post(sendNoteURL, {
          hadith_id: this.id,
          part: this.part,
          trans: this.trans,
          note_name:  this.name? this.name: "",
          note_email: this.email? this.email: "",
          note_txt: this.note,
          suggested_trans: this.suggested_trans
        })
        .then((response) => {
          this.snackbar = true;
          this.dialog = false;
          this.note = "";
          this.suggested_trans = "";
        })
        .catch((error) => {
          alert(this.$t("failed"));
        });
      },

      validateNote()
      {
        if(! this.note) {
          this.validationError = this.$t('note');
          return false;
        }
        return true;
        if(this.email && ! this.validateEmail(this.email)) {
          this.validationError = this.$root.locale['Err_email'];
          return false;
        }

        this.validationError = "";
        return true;
      },

      validateEmail(email) {
        //let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //return re.test(email);
      }
    },
    
	}
</script>