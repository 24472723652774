import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import ContactUs from '../views/ContactUs.vue'
import Categories from '../views/Categories.vue'
import Hadeeths from '../views/Hadeeths.vue'
import HadeethContainer from '../views/HadeethContainer.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },

  {
    path: '/contact-us',
    name: 'contact_us',
    component: ContactUs,
  },
  {
    path: '/list-categories',
    name: 'categories',
    component: Categories,
  },

  {
    path: '/list-hadeeths',
    name: 'hadeeths',
    component: Hadeeths,
  },

  {
    path: '/hadeeth',
    name: 'hadeeth',
    component: HadeethContainer,
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: 'hash'
})

export default router
