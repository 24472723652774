export default {
    formatList(response) {
        if(typeof response == 'undefined') {
            return [];
        }

        return response;
    },

     formatSubCategories(response) {
        if(typeof response == 'undefined') {
            return [];
        }

         return response;
    },

    formatCategoriesInfo(response) {
        if(typeof response == 'undefined') {
            return [];
        }
        let result = [];
        let data = response;
        for(let id in data) {
            let group = [];
            
            if(typeof data[id].parents != 'undefined' && data[id].parents.length > 0) {
                group = group.concat(data[id].parents.reverse());
            }

            group.push({
                id: data[id].id,
                title: data[id].title,
            });

            result.push(group);
        }

        return result;
    }
}