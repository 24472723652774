<template>
  <div></div>  
</template>

<script>
// @ is an alias to /src

export default {
  mounted() {
    // after the main language has been set
    console.log(this.$root);
    this.$router.push({name: 'categories', query: {
      lang: this.$root.locale,
    }});
  }
}
</script>
