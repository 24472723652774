const { arrayToTree } = require('performant-array-to-tree');

export default {
    formatList(response) {
        if(typeof response == 'undefined') {
            return [];
        }

        return response.data;
    },

    formatSubCategories(response) {
        if(typeof response == 'undefined') {
            return [];
        }

        let categories = response.data.map((category) => {
            return {
                id: category.id,
                parentId: category.parent_id == "0"? null: category.parent_id,
                title: category.title,
            }
        });
        
        return arrayToTree(categories, {
            childrenField: 'sub_categories',
            dataField: null,
        });
    },

    formatCategoriesInfo(response) {
        if(typeof response == 'undefined') {
            return [];
        }
        let result = [];
        let data = response.data;
        for(let id in data) {
            let group = [];
            
            if(typeof data[id].parents != 'undefined' && data[id].parents.length > 0) {
                group = group.concat(data[id].parents.reverse());
            }

            group.push({
                id: data[id].id,
                title: data[id].title,
            });

            result.push(group);
        }

        return result;
    }
}