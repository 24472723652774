<template>
    <v-dialog v-model="dialog" scrollable>
      <v-card>
        <v-card-title>{{ $t('cats') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
            <SubCategories :cat_id="cat_id" :subCategories="subCategories"></SubCategories>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn color="primary darken-1" @click="dialog = false">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

import SubCategories from './SubCategories.vue';

export default {

    components: {
        SubCategories,
    },

    props: ['showSubCategories'],


    computed: {
        subCategories(){
            if(! this.$root.subCategories[this.$root.locale] || ! this.$root.subCategories[this.$root.locale].length){
                return [];
            }
            else{
                return this.$root.subCategories[this.$root.locale];
            }
        },
        dialog: {
            get() {
                return this.showSubCategories;
            },

            set(newval) {
                this.$emit('update:showSubCategories', newval);
            }
        },
        cat_id(){
            return this.$route.query.cat_id || null
        },

    },
    
    watch: {
        dialog(val){
            
            if(val){
                setTimeout(() => {
                    document.getElementById("active-subcat").scrollIntoView();    
                }, 500);

            let query = {...this.$route.query, ...{"d":"1"}};
                this.$router.push({
                    query: query
                }).catch(()=>{});
            }
            else{
                if(! this.$route.query.d) return;
                history.go(-1);
            }            
        },
        "$route.query.d"(val){
            if(! val) this.dialog = false;
        }        
    }
}
</script>